<template>
  <v-dialog v-model="dialog" max-width="1000px" persistent>
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="close" />
        Chakra: {{ `${selectedChakra.order} - ${selectedChakra.name}` }}
      </v-card-title>
      <v-card-text>
        <property-value
          property="Localização"
          :value="selectedChakra.location"
        />
        <div
          v-if="!!selectedChakra.image"
          class="grey lighten-4 d-flex justify-center my-5"
        >
          <v-img :src="selectedChakra.image" max-width="150" contain />
        </div>
        <property-value v-if="selectedChakra.explanation" property="Explicação">
          <template #value>
            <explanation-icon
              :explanation="selectedChakra.explanation"
              :name="selectedChakra.name"
            />
          </template>
        </property-value>
        <property-value
          v-if="selectedChakra.homeUse"
          property="Recomendação para uso em casa"
          :value="selectedChakra.homeUse"
        />
        <v-list>
          <span class="subtitle-1 black--text">
            Selecione as possíveis atuações
          </span>
          <v-list-item-group
            v-model="selectedProcedures"
            multiple
            color="primary"
          >
            <v-list-item
              v-for="(procedure, index) in selectedChakra.procedures"
              :key="procedure.name + index"
              class="list-item"
              :value="procedure"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-icon>
                    {{ active ? "check_circle" : "panorama_fish_eye" }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ procedure.name }}
                </v-list-item-title>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <comments v-model="comments" />
      </v-card-text>
      <div class="mt-5 d-flex flex-column">
        <v-btn color="secondary" x-large @click="addToDiagnose">
          Adicionar ao Diagnóstico
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    comments: () =>
      import("@/domains/therapy/shared/presentation/components/Comments"),
  },
  data: () => ({
    selectedProcedures: [],
    comments: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialogs.chakras;
      },
      set(value) {
        this.$store.commit("dialogs/setChakras", value);
      },
    },
    selectedChakra() {
      return this.$store.state.chakras.selectedChakra;
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    validateIfExistsInDiagnosis() {
      const exists = this.$store.state.diagnosis.diagnose.chakras.some(
        (diagnoseChakra) => diagnoseChakra.chakra.id === this.selectedChakra.id
      );

      if (exists) {
        this.$toasted.global.error({
          message: "Chakra já adicionado ao diagnóstico",
        });
        return false;
      }

      return true;
    },
    async addToDiagnose() {
      try {
        if (!this.validateIfExistsInDiagnosis()) return;

        const { therapistComments, patientComments } = this.comments;

        const diagnoseChakra = {
          therapistComments,
          patientComments,
          chakra: this.selectedChakra,
          procedures: this.selectedProcedures,
        };

        this.$store.commit("diagnosis/addChakra", diagnoseChakra);
        this.clear();
        this.$toasted.global.success({
          message: "Chakra adicionado ao diagóstico",
        });
        this.$store.dispatch("settings/openConfirm", {
          title: "Chacra adicionado",
          message: "Recomendamos que todos os chacras sejam alinhados",
          type: "confirm",
        });
        this.dialog = false;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    clear() {
      this.selectedProcedures = [];
    },
  },
};
</script>

<style scoped></style>
